<script setup>
import {
  addClass,
  addClassWithReflow,
  deleteClass,
  deleteClassWithReflow,
  replaceClass,
} from "./css.utils";
import {
  _getSuitableLocation,
  _getSuitableOrigins,
  _getSuitableSize,
  delayMs,
} from "./menu.utils";
import { getAutofitOrigins, getScrollPosition } from "./select.utils";

const props = defineProps({
  modelValue: { default: false },
  origins: { default: "left top" },
  positionAlign: { default: "vertical" },
  coords: { default: null },
  label: { default: null },
  autosize: { default: true },
});

const emit = defineEmits(["update:modelValue"]);
const attrs = useAttrs();

const isOpen = ref(false);

const cssX = ref(0);
const cssY = ref(0);
const cssMaxHeight = ref("auto");
const cssMaxWidth = ref("auto");
const cssOriginsX = ref("left");
const cssOriginsY = ref("top");

const popupRef = ref(null);
const overlayRef = ref(null);
const activatorRef = ref(null);

function watchProps() {
  watch(
    () => props.modelValue,
    () => (isOpen.value = props.modelValue)
  );

  watch(
    () => isOpen.value,
    () => {
      handleOpenClose();
      emit("update:modelValue", isOpen.value);
    }
  );
}

async function handleOpenClose() {
  if (isOpen.value) {
    await updateVisibleState();
    setTimeout(() => startOpenAnim());
  } else {
    startCloseAnim();
  }
}

function startOpenAnim() {
  console.log("startOpenAnim");

  addClass(popupRef.value, "popup-from", "visible");
  addClassWithReflow(popupRef.value, "popup-to", "popup-transition");

  addClass(overlayRef.value, "overlay-from", "visible");
  addClassWithReflow(overlayRef.value, "overlay-to");
}

function startCloseAnim() {
  console.log("startCloseAnim");

  popupRef.value.ontransitionend = () => {
    deleteClass(popupRef.value, "popup-from", "visible", "popup-transition");
    popupRef.value.ontransitionend = null;
  };

  overlayRef.value.ontransitionend = () => {
    deleteClass(overlayRef.value, "overlay-from", "visible");
    overlayRef.value.ontransitionend = null;
  };

  deleteClass(popupRef.value, "popup-to");
  deleteClass(overlayRef.value, "overlay-to");
}

function open() {
  isOpen.value = true;
}

function close() {
  isOpen.value = false;
}

function handleOnBlur(event) {
  if (!event.currentTarget.contains(event.relatedTarget)) close();
}

async function updateVisibleState() {
  // console.log("POPUP bounds:", popupRef.value.getBoundingClientRect());
  // console.log("POPUP:", popupRef.value);

  const origins = _getSuitableOrigins({
    coords: props.coords,
    activator: activatorRef.value,
    popup: popupRef.value,
    positionAlign: props.positionAlign,
    defaultOrigins: props.origins,
  });

  console.log("origins: ", JSON.stringify(origins, null, "  "));
  setOrigins(origins);

  await delayMs(1);

  if (props.autosize && !popupRef.value.autosize) {
    const size = _getSuitableSize({
      coords: props.coords,
      activator: activatorRef.value,
      popup: popupRef.value,
      positionAlign: props.positionAlign,
      origins,
    });

    // console.log("size: ", JSON.stringify(size, null, "  "));
    setSize(size);
    popupRef.value.autosize = true;

    await delayMs(1);
  }

  // const location = _getSuitableLocation({
  //   coords: props.coords,
  //   activator: activatorRef.value,
  //   popup: popupRef.value,
  //   positionAlign: props.positionAlign,
  //   origins,
  // });

  // console.log("location: ", JSON.stringify(location, null, "  "));
  // await delayMs(1);

  // setLocation(location);

  const autofitOrigins = getAutofitOrigins({ origins });
  setOrigins(autofitOrigins);

  const autofixLocation = _getSuitableLocation({
    coords: props.coords,
    activator: activatorRef.value,
    popup: popupRef.value,
    positionAlign: props.positionAlign,
    origins: autofitOrigins,
  });

  setLocation(autofixLocation);
}

function setOrigins(suitableOrigins) {
  //set animation direction
  cssOriginsX.value = suitableOrigins.x;
  cssOriginsY.value = suitableOrigins.y;
}

function setSize(suitableSize) {
  cssMaxHeight.value = toPixels(suitableSize.height);
  cssMaxWidth.value = toPixels(suitableSize.width);
}

function setLocation(suitableBounding) {
  const { scrollX, scrollY } = getScrollPosition(activatorRef.value);
  // fit position
  cssX.value = toPixels(scrollX + suitableBounding.left);
  cssY.value = toPixels(scrollY + suitableBounding.top);
}

onMounted(() => {
  watchProps();
});
</script>
<template>
  <div class="v-menu-wrapper">
    <div class="v-menu" ref="activatorRef">
      <div class="menu-label" v-if="label">{{ label }}</div>
      <slot name="activator" :on="open" :state="isOpen" :close="close"></slot>
    </div>

    <teleport to="body">
      <div ref="popupRef" class="popup hidden" tabindex="1" @blur="handleOnBlur">
        <slot :close="close"></slot>
      </div>
    </teleport>
    <teleport to="body">
      <div ref="overlayRef" class="overlay hidden" @click.stop="close()"></div>
    </teleport>
  </div>
</template>
<style scoped>
.v-menu-wrapper {
  display: inline;
  position: relative;
}
.v-menu {
  display: inherit;
  align-items: center;
  justify-content: center;
}
.hidden {
  visibility: hidden;
  pointer-events: none;
}
.visible {
  visibility: visible !important;
  pointer-events: all !important;
}
.popup {
  position: fixed;
  top: 0;
  left: 0;

  /* padding: 8px 0px; */
  border-radius: 12px;
  box-sizing: border-box;
  box-shadow: 0px 4px 11px 0px var(--menu-shadow, #eee);

  color: var(--root-dark, #000);
  background-color: var(--root-white, #fff);

  /* animation */
  transform: translate3d(-200%, 0%, 0) scale(1);

  overflow-y: auto;
  overflow-x: hidden;

  outline: none;
  z-index: 99999999;

  max-height: v-bind(cssMaxHeight);
  max-width: v-bind(cssMaxWidth);
  /* background-color: red; */

  /* padding-bottom: 20px; */
  /* max-width: 100%; */
  /* max-width: 90%; */
}

.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: #00000000;

  transition: opacity 0.2s cubic-bezier(0.2, 0, 0.2, 1);
  z-index: 99999;
}

.popup-from {
  opacity: 0;
  transform: translate3d(v-bind(cssX), v-bind(cssY), 0) scale(0.5);
  transform-origin: v-bind(cssOriginsY) v-bind(cssOriginsX);
}

.popup-to {
  opacity: 1;
  transform: translate3d(v-bind(cssX), v-bind(cssY), 0) scale(1);
  transform-origin: v-bind(cssOriginsY) v-bind(cssOriginsX);
}
.popup-transition {
  transition: all 0.2s ease-in-out;
}

.overlay-from {
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.overlay-to {
  opacity: 1;
  transition: all 0.2s ease-in-out;
}
/* 
.overlay-enter {
  animation-delay: 0.5s;
  animation: fade-anim 0.2s;
  animation-fill-mode: forwards;
}

.overlay-leave {
  animation-delay: 0.5s;
  animation: fade-anim 0.2s reverse;
} */

.menu-label {
  background-color: var(--root-white);
  font-size: 10px;
  border-radius: 10px;

  display: inline-block;
  padding: 1px 4px;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 20;
  transform: translate3d(5px, -50%, 0);
}

@media (max-width: 380px) {
  .popup {
    max-width: v-bind(cssMaxWidth);
    max-height: v-bind(cssMaxHeight);
  }
}
</style>
